'use client';

import { useEffect, useRef } from 'react';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

const initPostHog = () => {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: '/api/ingest',
    autocapture: false,
    capture_pageleave: true,
    capture_pageview: false,
    capture_performance: true,
    disable_session_recording: true,
    disable_surveys: true,
    loaded: (posthog) => {
      if (process.env.NODE_ENV === 'development') {
        posthog.debug();
      }
    },
    persistence: 'localStorage',
    ui_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
  });
};

export function AnalyticsProvider({ children }: { children: React.ReactNode }) {
  const initialized = useRef(false);

  useEffect(() => {
    const shouldInitialize =
      typeof window !== 'undefined' &&
      process.env.NEXT_PUBLIC_POSTHOG_KEY &&
      process.env.NEXT_PUBLIC_POSTHOG_HOST &&
      process.env.NEXT_PUBLIC_ANALYTICS_ENABLED === 'true';
    if (!shouldInitialize) {
      return;
    }
    if (initialized.current) {
      return;
    }
    initPostHog();
    initialized.current = true;
  }, []);

  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
